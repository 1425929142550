import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';

export const padding = { base: 4, sm: 5, md: 6, lg: 8 };
export const inversePadding = { base: padding.base * -1, sm: padding.sm * -1, md: padding.md * -1, lg: padding.lg * -1 };

export const breakpoints = createBreakpoints({
	sm: '40em',
	md: '52em',
	lg: '68em',
	xl: '80em',
});

export const brandColors = {
	'blue': '#196EFA',
	'brown.300': '#B97E34',
	'brown': '#7F4C1E',
	'gray.200': '#DBE0EE',
	'gray': '#ABB2AA',
	'green.100': '#B0CFC4',
	'green.200': '#526D69',
	'green.700': '#00513C',
	'green': '#508054',
	'orange': '#FD542F',
	'pink.100': '#ffeded',
	'pink.300': '#ffdbd9',
	'pink': '#FFB7B4',
	'purple': '#9083B5',
	'red': '#EE2E22',
	'tan': '#FFB18E',
	'yellow.300': '#F4C054',
	'yellow': '#FBB017',
};

const fontSizes = {
	'xs': '0.7rem',
	'sm': '0.8rem',
	'md': '1.0rem',
	'lg': '1.25rem',
	'xl': '1.563rem',
	'2xl': '1.953rem',
	'3xl': '2.441rem',
	'4xl': '3.052rem',
	'5xl': '3.815rem',
	'6xl': '4.768rem',
	'7xl': '5.96rem',
	'8xl': '7.451rem',
};

// function getDefaults(props: Record<string, any>) {
//   const { focusBorderColor: fc, errorBorderColor: ec } = props;
//   return {
//     focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
//     errorBorderColor: ec || mode('red.500', 'red.300')(props)
//   };
// }

function variantMinimal() {
	// const { theme } = props;
	// const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

	return {
		field: {
			// 'borderBottom': '1px solid rgba(0,0,0,.1)',
			// borderColor: 'inherit',
			'borderRadius': 0,
			'pl': 0,
			'pr': 0,
			'bg': 'transparent',
			'_readOnly': {
				boxShadow: 'none !important',
				userSelect: 'all',
			},
			'::-webkit-input-placeholder': {
				opacity: 0.6,
				color: '#000',
			},
			'_focus': {
				// borderColor: getColor(theme, fc),
				'::-webkit-input-placeholder': {
					opacity: 0.3,
					color: '#000',
				},
			},
			'_invalid': {
				// borderColor: getColor(theme, ec)
			},
		},
		addon: {
			borderBottom: '1px solid rgba(0,0,0,.1)',
			// borderColor: 'inherit',
			borderRadius: 0,
			paddingX: 0,
			bg: 'transparent',
		},
	};
}

// const config = {
//   initialColorMode: 'light',
//   useSystemColorMode: false
// };

// 3. Extend the theme
export const theme = extendTheme({
	breakpoints,
	fontSizes,
	fonts: {
		heading: `Mabry, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
		body: `Mabry, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
	},
	styles: {
		global: (props) => ({
			'html': {
				fontSize: { base: '20px', xl: '1.48889vw' },
				height: '100%',
			},
			'body': {
				color: mode('black', 'white')(props),
				// bg: mode('white', 'gray.800')(props),
				bg: 'unset',
				lineHeight: 1.4,
				overflowY: 'scroll',
				fontWeight: 100,
				height: '100%',
			},
			'p:not(:last-child))': {
				mb: 8,
			},
			'ul': {
				ml: '0 !important',
				pl: 0,
			},
			'li': {
				display: 'list-item',
				listStyleType: 'none',
				mb: 6,
				// ml: 10,
				pl: 0,
			},
			'li:before': {
				display: 'inline-block',
				// content: '"→"',
				width: 10,
				ml: -10,
				pl: 0,
			},
			'.navigation-expanded ul li': {
				display: 'default',
				mb: 0,
				ml: 0,
			},
			'.navigation-expanded ul li:before': {
				display: 'block',
				content: '""',
			},
			'li.chakra-wrap__listitem:before': {
				content: '""',
			},
			'.youtubeVideo': {
				width: '100%',
				height: '100%',
			},
		}),
	},
	colors: {
		brand: {
			...brandColors,
		},
	},
	components: {
		Grid: {
			defaultProps: {
				variant: 'normal',
			},
			variants: {
				normal: {
					templateColumns: 'repeat(8, minmax(0, 1fr))',
					columnGap: 4,
					rowGap: 8,
					px: padding,
					bg: 'red.500',
				},
			},
		},
		Button: {
			defaultProps: {
				variant: 'minimal',
			},
			variants: {
				minimal: {
					borderRadius: 'full',
					transition: 'all .2s ease-in-out',
					_hover: {
						opacity: 0.5,
					},
				},
				solid: {
					background: 'black',
					color: 'white',
					borderRadius: 'full',
					transition: 'all .2s ease-in-out',
					_hover: {
						background: 'black',
						color: 'white',
						opacity: 0.5,
						_disabled: {
							background: 'black',
							color: 'white',
							opacity: 0.5,
						},
					},
				},
				pill: {
					background: 'black',
					borderRadius: 'full',
					color: 'white',
					height: 'auto',
					lineHeight: 1,
					fontWeight: 400,
					padding: '0.25em 2em 0.5em 2em',
					transition: 'all 0.2s ease-in-out',
					_hover: {
						background: 'black',
						color: 'white',
						opacity: 0.5,
						_disabled: {
							background: 'black',
							color: 'white',
							opacity: 0.5,
						},
					},
				},
			},
		},
		Input: {
			defaultProps: {
				variant: 'minimal',
			},
			variants: {
				minimal: variantMinimal,
			},
		},
		Select: {
			baseStyle: {
				field: {
					borderRadius: 0,
				},
				icon: {
					right: '.25rem',
					fontSize: '1rem',
				},
			},
			variants: {
				outline: {
					field: {
						borderRadius: 0,
						_hover: {
							borderColor: 'black',
						},
						_focus: {
							borderColor: 'black',
						},
					},
				},
			},
		},
		Link: {
			defaultProps: {
				variant: 'minimal',
			},
			variants: {
				minimal: {
					transition: 'all .2s ease-in-out',
					_hover: {
						opacity: 0.5,
						textDecoration: 'none',
					},
					_focus: {
						boxShadow: 'none',
					},
				},
				solid: {
					textAlign: 'center',
					padding: '2',
					background: 'black',

					color: 'white',
					borderRadius: 'full',
					transition: 'all .2s ease-in-out',
					_hover: {
						background: 'black',
						color: 'white',
						opacity: 0.5,
						textDecoration: 'none',
					},
					_disabled: {
						cursor: 'not-allowed',
						opacity: 0.5,
					},
				},
			},
		},
		Text: {
			variants: {
				meta: {
					opacity: 0.5,
				},
			},
		},
	},
});
