import * as React from 'react';

type SiteContextType = {
	autoScrollTargetRef: React.RefObject<HTMLDivElement>;
	closeHamburger: () => void;
	closeSearch: () => void;
	currentPath: string;
	isHamburgering: boolean;
	isSearching: boolean;
	openHamburger: () => void;
	openSearch: () => void;
	searchTerms: string[];
	setSearchTerms: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SiteContext = React.createContext<SiteContextType>({} as SiteContextType);

export default SiteContext;
