import '../theme/screen.css';
import 'focus-visible/dist/focus-visible';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Fonts from '../components/Fonts';
import React from 'react';
import castArray from 'lodash/castArray';
import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { RouterScrollProvider } from '@moxy/next-router-scroll';
import { SiteContext } from '@components/SiteContext';
import { initClarity } from '@lib/clarity';
import { initGA, logPageView } from '@lib/ga';
import { theme } from '@theme';
import { useDisclosure } from '@chakra-ui/react';

export default function App({ Component, pageProps, router }: AppProps) {
	const autoScrollTargetRef = React.useRef<HTMLDivElement>(null);
	const searchModal = useDisclosure();
	const [isHamburgering, setIsHamburgering] = React.useState(false);
	const [isRouteChanging, setIsRouteChanging] = React.useState(false);
	const openHamburger = React.useCallback(() => setIsHamburgering(true), [isHamburgering, setIsHamburgering]);
	const closeHamburger = React.useCallback(() => setIsHamburgering(false), [isHamburgering, setIsHamburgering]);
	const [searchTerms, setSearchTerms] = React.useState<string[]>([]);
	const [currentPath, setCurrentPath] = React.useState(router.pathname.replace('[slug]', (router.query['slug'] as string) ?? '[slug]'));

	/**
	 * @param boolean onScroll
	 * @return void
	 */
	function setupAnalytics(onScroll: boolean = false) {
		if (getCookieConsentValue() && onScroll === false) {
			// Initialize the Google Analytics library
			initGA();

			// Trigger a page view
			logPageView();

			// Listen for more page views
			router.events.on('routeChangeComplete', logPageView);
		}
	}

	// Initialize tracking (once)
	React.useEffect(() => {
		initClarity();

		// mk: Was removed "for legal reasons" before.
		// Presumably because we didn't have a cookie banner?
		setupAnalytics();
	}, []);

	React.useEffect(() => {
		const routerTerms = router?.query?.terms ? castArray(router?.query?.terms) : [];
		setSearchTerms(routerTerms);
	}, [router?.query?.terms]);

	React.useEffect(() => {
		setCurrentPath(router.pathname.replace('[slug]', (router.query['slug'] as string) ?? '[slug]'));

		// Allows us to escape React and build this normally
		document.body.setAttribute('path', router.asPath);
	}, [router.pathname, router.query['slug']]);

	React.useEffect(() => {
		if (searchModal.isOpen) {
			searchModal.onClose();
		}

		if (isHamburgering) {
			closeHamburger();
		}
	}, [currentPath]);

	return (
		<SiteContext.Provider
			value={{
				autoScrollTargetRef,
				closeHamburger,
				closeSearch: searchModal.onClose,
				currentPath: currentPath,
				isHamburgering,
				isSearching: searchModal.isOpen,
				openHamburger,
				openSearch: searchModal.onOpen,
				searchTerms,
				setSearchTerms,
			}}
		>
			<ChakraProvider theme={theme} resetCSS>
				<Fonts />

				<RouterScrollProvider>
					<AnimatePresence exitBeforeEnter>
						<Component {...pageProps} key={router.asPath} />

						<CookieConsent buttonText="Got it!" onAccept={setupAnalytics}>
							This website uses cookies to anonymously track usage. No personally identifiable information is stored or shared.
						</CookieConsent>
					</AnimatePresence>
				</RouterScrollProvider>
			</ChakraProvider>
		</SiteContext.Provider>
	);
}
