import { Global } from '@emotion/react';

export const Fonts = () => (
	<Global
		styles={`
      /* latin */
      @font-face {
        font-family: Mabry;
        font-style: normal;
        src: url('/fonts/mabry-light-pro.woff') format('woff');
      }
      `}
	/>
);

export default Fonts;
