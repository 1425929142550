import * as Config from '../config';
import ReactGA from 'react-ga4';

const useAnalytics = process.env.NODE_ENV !== 'development';

export const initGA = () => {
	console.log('Initialize Google Analytics');

	if (useAnalytics) {
		ReactGA.initialize(Config.GOOGLE_ANALYTICS_ID);
	}
};

export const logPageView = () => {
	console.log('GA - Pageview:', location.pathname);

	if (useAnalytics) {
		ReactGA.set({ page: location.pathname });
		ReactGA._gaCommandSendPageviewParameters(location.pathname);
	}
};

export const logEvent = (category = '', action = '') => {
	if (useAnalytics && category && action) {
		ReactGA.event({ category, action });
	}
};

export const logException = (description = '', fatal = false) => {
	if (useAnalytics && description) {
		ReactGA.send({ description, fatal });
	}
};
